<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-row>
                <v-col cols="12" sm="12">
                    <v-toolbar dense flat class="transparent mb-1" height="40">
                        <v-toolbar-title>
                            <span class="headline text-uppercase">Página en Construcción</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
    export default {
        name: "UnderConstruction",
        title: "Página en Construcción | Turismo BC",
    }
</script>

<style scoped>

</style>