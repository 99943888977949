<template>
    <div>
        <about-component/>
        <footer-component/>
    </div>
</template>
<script>
    import AboutComponent from "../components/about-us/AboutComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";

    export default {
        name: "About",
        title: "Quienes Somos | Turismo BC",
        components: {AboutComponent, FooterComponent}
    }
</script>