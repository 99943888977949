<template>
    <v-container class="fill-height text-left pa-0" fluid>
        <v-row>
            <v-col>
                <v-card class="fill-height container--fluid" flat tile width="100vw">
                    <v-container class="fill-height text-left pa-0" fluid>
                        <v-row justify="start">
                            <v-col cols="12">
                                <v-card class="d-flex fill-height d-flex fluid pa-0 ma-0" flat tile width="100vw">
                                    <v-scroll-x-transition appear>
                                        <v-img
                                            height="200"
                                            max-height="200"
                                            position="center"
                                            src="@/assets/images/bg-breadcrumbs.jpg"
                                            transition="slide-x-transition"
                                            width="100%">
                                            <v-container
                                                class="fill-height align-end"
                                                fluid>
                                                <v-row align="end" justify="start">
                                                    <v-col cols="12">
                                                        <div class="white--text">
                                                            <span class="white--text pl-5 text-h4 text-md-h3 font-weight-black">
                                                                Quiénes somos...
                                                            </span>
                                                        </div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-breadcrumbs :items="items" dark>
                                                            <template v-slot:divider>
                                                                <v-icon>mdi-arrow-right</v-icon>
                                                            </template>
                                                            <template v-slot:item="{ item }">
                                                                <v-breadcrumbs-item
                                                                    :disabled="item.disabled"
                                                                    :href="item.href">
                                                        <span class="white--text">
                                                            {{ item.text }}
                                                        </span>
                                                                </v-breadcrumbs-item>
                                                            </template>
                                                        </v-breadcrumbs>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-img>
                                    </v-scroll-x-transition>
                                </v-card>
                            </v-col>
                            <v-col class="align-center justify-start" cols="12">
                                <v-card class="ma-1" flat tile>
                                    <v-container class="fill-height" fluid>
                                        <v-row justify="start">
                                            <v-col cols="12" md="6">
                                                <v-img
                                                    height="auto"
                                                    max-height="500"
                                                    width="100%"
                                                    position="center"
                                                    contain
                                                    src="@/assets/images/logo-bhc.png"
                                                    transition="slide-x-transition">
                                                </v-img>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-container fluid class="text-left">
                                                    <v-row justify="start">
                                                        <v-col cols="12">
                                                            <span class="text-h5 text-md-h4 font-weight-bold headline text-uppercase">
                                                                Acerca de nosotros
                                                            </span>
                                                            <div class="boxdivider"></div>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-responsive class="pt-1" :max-width="$vuetify.breakpoint.xsOnly ? 400 : '100%'">
                                                            <span class="text-body-1 font-weight-light grey--text text--darken-2">
                                                                <p>
                                                                    <strong>Turismo BC</strong> es una organización que supervisa y administra a través del asesoramiento y
                                                                    promoción de nuestros afiliados médicos, dentales y hospitalarios, que reciben miles de pacientes del
                                                                    exterior.
                                                                </p>
                                                                <p>
                                                                    Somos el punto de conexión entre el paciente y el proveedor de salud; la fusión de calidad, calidez y
                                                                    seguridad para los pacientes. Promovido por la Secretaría de Turismo del Estado de Baja California.
                                                                </p>
                                                            </span>
                                                            </v-responsive>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "AboutComponent",
    data: () => ({
        items: [
            {
                text: 'Home',
                disabled: false,
                href: '/homepage',
            },
            {
                text: 'Quiénes Somos',
                disabled: true,
                href: '/about',
            }
        ],
    }),
}
</script>

<style scoped>
/*.v-image__image{
    background-size:100% 100%;
}*/
.bottom-gradient {
    background-image: linear-gradient(
        to bottom,
        rgba(163, 153, 248, 0.295) 0%,
        rgba(101, 99, 248, 0.699) 100%
    );
}

.blend-opacity {
    opacity: 0.1;
    transition: opacity 0.4s ease-in-out;
    background-color: black;
    background-blend-mode: normal;
}

.boxdivider {
    background-color: transparent;
    width: 100px;
    height: 20px;
    color: #ffffff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom-width: 3px;
    border-bottom-color: blueviolet;
    border-bottom-style: solid;
    display: block;
}
</style>